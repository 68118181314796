/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'truck-front': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5 11a1 1 0 11-2 0 1 1 0 012 0m8 0a1 1 0 11-2 0 1 1 0 012 0m-6-1a1 1 0 100 2h2a1 1 0 100-2zM4 2a1 1 0 00-1 1v3.9c0 .625.562 1.092 1.17.994C5.075 7.747 6.792 7.5 8 7.5s2.925.247 3.83.394A1.008 1.008 0 0013 6.9V3a1 1 0 00-1-1zm0 1h8v3.9q0 .002 0 0l-.002.004-.005.002h-.004C11.088 6.761 9.299 6.5 8 6.5s-3.088.26-3.99.406h-.003l-.005-.002L4 6.9q0 .002 0 0z"/><path pid="1" d="M1 2.5A2.5 2.5 0 013.5 0h9A2.5 2.5 0 0115 2.5v9c0 .818-.393 1.544-1 2v2a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5V14H5v1.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a2.5 2.5 0 01-1-2zM3.5 1A1.5 1.5 0 002 2.5v9A1.5 1.5 0 003.5 13h9a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0012.5 1z"/>',
    },
});
